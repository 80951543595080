$fixedHeaderHeight: 140px;
$initialHeaderHeight: 210px;

.mobile-article {
  &--scrolled {
    padding-top: $initialHeaderHeight;

    & .mobile-article {
      &__header {
        position: fixed;
        height: $fixedHeaderHeight;
        top: 0;
      }
    }
  }

  &__back-btn {
    position: fixed;
    z-index: 100;
    top: 60px;
    left: $unitX;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__header {
    height: 210px;
    width: 100%;
    // fixes problem where fixed header disappears in ios
    transform: translateZ(0);
    position: relative;
    z-index: 17;

    & .background-image__image {
      background-position: bottom;
    }
  }

  &__article {
    color: $textColor-darkGrey;
    padding: $mobileTextPadding;

    iframe {
      width: 100%;
      background-color: $themeColor-LightGrey;
    }
  }

  &__date,
  &__content {
    @include body-copy;
  }

  &__date {
    margin-bottom: 1em;
  }

  &__title {
    margin-bottom: 0.4rem;
  }
}

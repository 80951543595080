.productPage {
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    min-height: 20rem;
    height: 20rem;
    color: black;
    align-self: center;

    @media (max-width: 1024px) {
      width: 75%;
    }

    h1 {
      @include fluid-type($min-vw, $max-vw, 28px, 48px);

      @media (max-width: 1024px) {
        font-size: 25px;
      }
    }

    .subtitleContainer {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      cursor: pointer;
    }

    .subtitle {
      font-weight: 550;
      margin-right: 0.5rem;
      @include fluid-type($min-vw, $max-vw, 14px, 16px);
    }

    .icon {
      transform: scale(0.9);
    }
  }

  .tiles {
    position: relative;
    height: 100vh;
  }
}

.long-form-header {
  min-height: 100vh;
  width: 100%;
}
.long-form-header__image {
  height: 60vh;
  background: #282828;
  background: radial-gradient(#303030, #282828);
  background: radial-gradient(circle at center, #373737, #202020);
  @media (max-width: 768px) {
    height: 50vh;
  }
}
.long-form-header__info {
  height: 40vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  justify-content: space-evenly;
  background: #282828;
  background: radial-gradient(#303030, #282828);
  background: radial-gradient(circle at center, #373737, #202020);

  @media (max-width: 768px) {
    height: auto;
    min-height: 50vh;
    padding: 1rem;
    & > * {
      padding: 0.5rem 0;
    }
  }
}
.long-form-header__icon {
  height: 80px;
  @media (max-width: 768px) {
    height: 70px;
  }
}

.long-form-header__scroll-button {
  @media (max-height: 767px) {
    display: none;
  }
}
.long-form-header__scroll-icon {
  width: 18px;
}

.long-form-header__title {
  @include fluid-type($min-vw, $max-vw, 54px, 72px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 40px, 54px);
  text-align: center;
  margin: 0;
}
.long-form-header__sub-title {
  @include fluid-type($min-vw, $max-vw, 40px, 62px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 32px, 40px);
}
.long-form-header__description {
  font-weight: bold;
  color: #cbcbcb;
  text-align: center;
  max-width: 40em;
}

.mobile-menu {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  padding-top: $mobileHeaderHeight;
  padding-bottom: 3 * $unitY;
  background-color: $themeColor-DarkOverlay;
  color: white;
  display: none;
  overflow: auto;
  z-index: 15;

  &--open {
    display: block;
  }

  &--sub-menu-open {
    & .mobile-menu__link {
      opacity: 0.5;

      &--active {
        opacity: 1;
      }
    }
  }

  &__site-nav {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__link {
    display: block;
    margin: 0 auto;
    width: 100%;
    line-height: 4rem;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }

  &__sub-menu {
    display: none;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    &--open {
      display: block;

      & .mobile-menu__link {
        opacity: 1;
      }
    }

    & .mobile-menu__link {
      font-size: 19px;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 0.5 * $unitY $unitX;
    display: flex;
    justify-content: space-between;
  }

  &__footer-link {
  }
}

.long-form-feature {
  @include long-form-block;
  flex-direction: row;
  &:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
}
.long-form-feature__text {
  @include long-form-block-inner;
  text-align: center;
  line-height: 1.5em;
}
.long-form-feature__title {
  margin-bottom: 1rem;
}
.long-form-feature__content-text {
  @include fluid-type-small;
  color: #666;

  br {
    line-height: 2.2em;
  }
}
.long-form-feature__link-wrapper {
  margin-top: 1rem;
}
.long-form-feature__link {
  @include read-more-link(inherit, 1px);
  @include fluid-type-small;
  color: #666;
}
.long-form-feature__image {
  @include long-form-block-inner;
}
.long-form-feature__image-inner-wrap {
  @include aspect-ratio(450, 352);
  width: 100%;
  position: relative;
  padding-bottom: 78.22222%;
}
.long-form-feature__image--video {
}

@media (max-width: 768px) {
  .long-form-feature {
    &:nth-of-type(odd) {
      flex-direction: column;
    }
  }
  .long-form-feature__link-wrapper {
    margin-top: 0;
  }
  .long-form-feature__content-text {
    margin-bottom: 1rem;
  }
}

.pagewrap {
  ///position: fixed;

  position: absolute;
  overflow-y: auto;
  min-width: 100%;

  @media screen and (min-width: 1024px) {
    position: static;
    overflow-y: hidden;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &.home {
    min-height: 80vh;
  }
}
.layout--overlay-open {
  .footer {
    color: white;
    position: fixed;
  }
}

.article {
  display: flex;
}

.article__article {
  width: $rightColWidth;
  padding: $siteMarginY 0.5rem $siteMarginY $textPaddingX;
  display: flex;
  flex-direction: column;
  height: 100vh;

  iframe {
    width: 100%;
    background-color: $themeColor-LightGrey;
  }
}
.article__title {
  margin-bottom: 1rem;
}
.article__back-btn {
  margin-bottom: 2em;
}

.article__back-btn-link {
  display: flex;
  align-items: center;
  z-index: 10;

  svg {
    margin-right: 5px;
    height: 20px;
  }
}

.article__cover-image {
  flex-basis: 0;
  flex-grow: 1;
  height: 100vh;
  position: relative;
  background: radial-gradient(transparent, black);
}

.article__content {
  // height: calc(100vh - #{2 * $siteMarginY});
  // flex-grow: 1;
  position: relative;
}

.article__content:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.3em;

  @include gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.article__date {
  padding-bottom: 1.2em;
}


/// Added by Andi 25/11/2019
.scroll-bar__content-inner {
  
  iframe {
    min-height: 250px;
  }

  @media screen and (min-width: 1024px){
    
    .has-yt {
      .iframe-wrapper {
        display: none;
      }
    }

  }

}
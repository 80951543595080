.long-form {
  flex-grow: 1;
}
.long-form--404 {
  background: #282828;
  color: white;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.long-form__get-in-touch-fab {
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 8.33333vw;
  padding: 0.7rem 0.8rem;
  background: $themeColor-Orange;
  border-top-right-radius: $btnRadius;
  border-top-left-radius: $btnRadius;
  display: flex;
  align-items: center;
  justify-content: center;

  &--hidden {
    display: none;
  }
}
.long-form__get-in-touch-fab-icon {
  border: 1px solid white;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding-top: 2px;
}
.long-form__features {
  color: black;
  position: relative;
}
.long-form__feature-title {
  margin-bottom: 1rem;
}
.scroll-anchor {
  position: absolute;
  top: calc(-3em - 40px);
}

$trackMargin: 11px;
$thumbSize: 9px;
$browserScrollbarWidth: 17px;

.scroll-bar {
  position: relative;
  height: 100%;
}

.scroll-bar__hider {
  position: absolute;
  right: 0;
  width: 20px;
  height: 100%;
}

.scroll-bar__track {
  position: absolute;
  right: 0;
  top: $trackMargin;
  bottom: $trackMargin;

  &--hidden {
    visibility: hidden;
  }
}

.scroll-bar__line {
  width: 1px;
  background-color: #ccc;
  height: 100%;
  padding-top: $trackMargin;
  padding-bottom: $trackMargin;
  right: $thumbSize/2 - 1;
  position: absolute;
}

.scroll-bar__thumb {
  height: $thumbSize;
  width: $thumbSize;
  margin-top: -$thumbSize/2;
  border-radius: $thumbSize/2;
  background-color: #555;
  position: absolute;
  right: 0;
}

.scroll-bar__content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.scroll-bar__content {
  overflow-y: scroll;
  height: 100%;
  width: calc(100% + #{$browserScrollbarWidth});
  padding-right: $browserScrollbarWidth;
}

.scroll-bar__content-inner {
  // To avoid collapsed margins
  overflow: auto;
}

.scroll-bar--with-padding {
  .scroll-bar__content-inner {
    padding-right: 30px;
  }
}

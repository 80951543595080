.mobile-header {
  height: $mobileHeaderHeight;
  position: fixed;
  z-index: 16;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  transition: background 0.65s ease;

  &--scrolled.mobile-header {
    background-color: black;
    color: white;
  }

  &--scrolled.mobile-header--dark {
    background-color: white;
    color: black;
  }

  &--menu-open.mobile-header,
  &--legal-open.mobile-header {
    background-color: transparent;
    color: white;
  }

  &--transparent.mobile-header {
    background-color: transparent;
  }

  &__logo {
    width: 80px;
    position: fixed;
    z-index: 16;
    top: $unitY;
    left: $unitX;

    & svg {
      width: 100%;
      height: 28px;
    }
  }

  &__menu-btn {
    position: fixed;
    right: 0;
    z-index: 16;
    top: $unitY;
    right: $unitX;
  }
}

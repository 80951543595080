.long-form-banner {
  background: #f5f5f5;
  width: 100%;
  padding: 2em 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @include fluid-type($min-vw, $max-vw, 40px, 62px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 26px, 40px);
}

.long-form-banner__text {
  max-width: 20em;
  margin: 0 auto;
  margin-bottom: 0.4em;
  @include fluid-type($min-vw, $max-vw, 40px, 62px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 26px, 40px);
  font-weight: bold;
}
.long-form-banner__author {
  max-width: 60rem;
  margin: 0 auto;
  @include fluid-type($min-vw, $max-vw, 25px, 38px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 18px, 25px);
  font-weight: bold;
}
.long-form-banner__company {
  max-width: 60rem;
  margin: 0 auto;
  @include fluid-type($min-vw, $max-vw, 22px, 30px);
  @include fluid-type($mob-min-vw, $mob-max-vw, 16px, 22px);
  display: block;
}

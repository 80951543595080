@mixin blog-width {
  width: 70%;
  max-width: 45rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem 0 1rem;
  }
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.blog__header {
  @extend .long-form-header;
  min-height: auto;

  @media (max-width: 768px) {
    .long-form-header__image {
      height: 27.5vh;
    }

    .long-form-header__info {
      min-height: 20vh;
    }
  }
}

.blog__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  .blog__content__header {
    padding-top: 4rem;
    @include blog-width;
  }

  .blog__content__section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .blog__content__text {
      @include blog-width;
    }

    .blog__content__banner {
      margin: 4rem 2rem;
    }

    .blog__content__image {
      @include blog-width;
      margin: 1rem 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2em;
  }

  p {
    margin-top: 2em;
    @extend .long-form-feature__content-text;
  }

  img {
    width: 100%;
  }
}

.blog__information {
  display: flex;

  .blog__metadata {
    margin: 2rem 0;
    flex: 1 1 auto;
  }
}

// TODO: Move into common
.iframe-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-top: 2em;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

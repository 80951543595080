.vid-overlay {
  position: absolute;
  ///top: 25%;
  ///left: 20%;
  z-index: 10;
  padding: 15.5vh 0;
  background: rgba(0,0,0,0.75);
  ///height: 375px;
  display: table;

  height: 100%;
  width: 100%;

  h3 {
    
    text-align: center;
    padding: 10px;
    display: block;
    max-width: 460px;
    margin: 0 auto;
    /* word-wrap: break-word; */
    /* word-break: break-all; */
    vertical-align: top;
    color: #fff;

    span {
      font-size: 0.8em;
      color: rgba(237, 149, 0, 1);
    }

  }
  iframe {
      height: 315px;
      display: block;
      margin: 050px auto;
  }
}

.youtube-player-standalone {
  @include aspect-ratio(16, 9);
}

.youtube-player-standalone__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
}
.youtube-player-standalone__cover-image {
  position: absolute;
  z-index: 1;
}
.youtube-player-standalone__cover-image--active {
  display: none;
}
.youtube-player-standalone__cover-image--no-cover {
  background: #282828;
}

.youtube-player-standalone__video {
  iframe {
    width: 100%;
    height: 100%;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
}

$siteMarginX: 100px;
// $siteMarginY: 100px;
$textPaddingX: 50px;

// below is used for fluid font sizing
$mob-min-vw: 320px;
$mob-max-vw: 600px;
$min-vw: 1024px;
$max-vw: 2048px;

$font-family: "Open Sans", sans-serif;
$fontSize-normal: 15px;
$fontSize-small: 13px;
$fontSize-xsmall: 12px;

$textColor-lightGrey: #bbb;
$textColor-midGrey: #777;
$textColor-darkGrey: #222;

$lineHeight-wide: 1.7em;
$lineHeight-medium: 1.4em;
$lineHeight-small: 1.1em;

$scrollPadding: 20px;
$scrollBarWidth: 14px;

$unitX: 8.33333333vw;
$unitY: 5.55555555vh;

$siteMarginX: $unitX;
$siteMarginY: 3 * $unitY;
$siteMarginYb: 2 * $unitY;
$rightColWidth: 4 * $unitX;

$carouselNavWidth: 25vw;

$mobileHeaderMarginY: $unitY;
$mobileHeaderInnerHeight: 20px;
$mobileHeaderHeight: calc(
  #{2 * $mobileHeaderMarginY} + #{$mobileHeaderInnerHeight}
);

$mobileTextPadding: $unitY $unitX;

$footerMenuWidth: calc(100vw - #{$carouselNavWidth} - 2 *#{$siteMarginX});
$navWidth: $footerMenuWidth;

$longFormOuterWidth: 90rem;
$longFormInnerWidth: 35rem;

$btnRadius: 3px;

$dropdownColor: #232323; /// Was - #a29aa5
$rolloverColor: #a29aa5;
$sbg_green: rgb(147, 227, 0);
$sbg_orange: rgb(237, 149, 0);
$sbg_red: rgb(173, 0, 3);

@keyframes lds-facebook_1 {
  0% {
    top: 20px;
    height: 160px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
@-webkit-keyframes lds-facebook_1 {
  0% {
    top: 20px;
    height: 160px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
@keyframes lds-facebook_2 {
  0% {
    top: 27.499999999999986px;
    height: 145.00000000000003px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
@-webkit-keyframes lds-facebook_2 {
  0% {
    top: 27.499999999999986px;
    height: 145.00000000000003px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
@keyframes lds-facebook_3 {
  0% {
    top: 35px;
    height: 130px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
@-webkit-keyframes lds-facebook_3 {
  0% {
    top: 35px;
    height: 130px;
  }
  50% {
    top: 50px;
    height: 100px;
  }
  100% {
    top: 50px;
    height: 100px;
  }
}
.sbg-spinner div {
  position: absolute;
  width: 20px;
}
.sbg-spinner div:nth-child(1) {
  left: 40px;
  background: #93e300;
  -webkit-animation: lds-facebook_1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: lds-facebook_1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sbg-spinner div:nth-child(2) {
  left: 90px;
  background: #ed9500;
  -webkit-animation: lds-facebook_2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: lds-facebook_2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.sbg-spinner div:nth-child(3) {
  left: 140px;
  background: #ad0003;
  -webkit-animation: lds-facebook_3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: lds-facebook_3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.sbg-spinner {
  position: relative;
  width: 48px !important;
  height: 48px !important;
  -webkit-transform: translate(-24px, -24px) scale(0.24) translate(24px, 24px);
  transform: translate(-24px, -24px) scale(0.24) translate(24px, 24px);
}

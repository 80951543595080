html,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  font-family: $font-family;
  color: $themeColor-Light;
  background-color: $themeColor-Light;
  font-size: $fontSize-normal;
  margin: 0;
  position: relative;
  width: 100%;
  min-height: 100%;
  z-index: 0;

  @media (min-width: 1024px) {
    // overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.6em;
    @include fluid-type($min-vw, $max-vw, 20px, 30px);
    line-height: $lineHeight-small;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  p {
    margin-top: 0;
    @include dont-break-out;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .link-fill::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  sup {
    // prevents weird behaviour where sups show over fixed header
    z-index: -1;
    line-height: 1;
  }

  img {
    max-width: 100%;
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: inherit;
    padding: 0;
    text-align: left;
  }
}

.api-failure {
  align-items: center;
  min-height: 100vh;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__logo {
    width: 15rem;
    margin-bottom: 2rem;
    max-width: 90%;
  }

  &__message {
    font-weight: bold;
    font-size: 1rem;
  }
}

.page {
  &--loading {
    &:after {
      content: '';
      display: block;
      position: fixed;
      background-color: $themeColor-LightGrey;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

}

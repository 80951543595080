.follow-on-links {
	padding: 20px 0;

	h6 {
		padding-bottom: 10px;
	}

	ul {
		li {
			display: inline-block;
			padding-right: 10px;
		}
	}
}
.header {
  position: fixed;
  top: 0;
  padding-top: 40px;
  left: 0;
  right: 0;

  padding-left: 8.33333vw;
  padding-right: 8.33333vw;
  height: calc(4em + 35px);
  z-index: 100;
  display: flex;
  justify-content: space-between;

  //// Added by Andi - 27/11/2019
  @media screen and (min-width: 1024px) {
    position: relative;
    padding-top: 0;
    left: auto;
    right: auto;

    background: rgba(0, 0, 0, 1);
    height: 10vh;
    align-items: center;
  }

  &--has-gradient {
    height: calc(6.4em + 40px);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      transparent 100%
    );
  }
}

.header__nav {
  width: $navWidth;
}

.header__menu {
  width: 100%;
  float: right;
  display: flex;
  justify-content: space-between;
}

.header__menu-item {
  position: static;

  & > .menu {
    background: linear-gradient(#000 30px, $dropdownColor 30px);
    .menu-item {
      padding-top: 0;
      padding-bottom: 8px;
      padding-left: 0;
    }
    .menu--level-1 {
      background: $dropdownColor;
    }
  }

  &:hover {
    & > .menu {
      display: block;

      //// Added By Andi - 27/11/2019
      display: flex;
      position: absolute;
      top: 60px;
      padding-top: 72px;
      padding-bottom: 50px;
      width: 100%;
      justify-content: space-between;
      background: linear-gradient(
        transparent 10px,
        #000 30px,
        $dropdownColor 30px
      );
      ///background: $dropdownColor;

      padding-left: 8.33333vw;
      padding-right: 8.33333vw;

      &.subheader-solutions {
        justify-content: space-evenly;
      }

      .menu-item {
        min-width: 216px;

        .menu-item__title {
          border-bottom: 1px solid #4a4a4a;
          display: block;
          padding-bottom: 10px;
        }
        .menu {
          display: block;
          left: auto;
          top: 10px;
          position: relative;

          .menu--level-1,
          .menu-item__link {
            color: #fff;
          }
        }
        &:hover {
          background: $dropdownColor;
        }
      }
    }

    border-radius: $btnRadius;
  }
  &--has-children {
    &:hover {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .icon {
    display: inline-block;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    object-fit: contain;
    position: relative;
    top: 5px;

    &.racewatch {
      background-image: url("../../img/HeaderIcon_RaceWatchMS-1.png");
    }
    &.matchtracker {
      background-image: url("../../img/VectorIcons_MatchTracker.png");
    }
    &.focus {
      background-image: url("../../img/VectorIcons_Focus.png");
    }
    &.fusion {
      background-image: url("../../img/VectorIcons_Fusion.png");
    }
    &.sbg-hub {
      background-image: url("../../img/Hub_green_logo-small.png");
    }
  }

  .menu-item {
    .menu-item--level-1 .icon {
      background-size: 12px 12px;
      top: 12px;
      @include transition(transform 0.25s ease-in-out);
    }
    &.racewatch .menu-item--level-1 {
      .icon.arrow {
        background-image: url("../../img/red-arrow.svg");
      }
      &:hover {
        .icon.arrow {
          @include translate(10px, 0);
        }
      }
    }
    &.matchtracker .menu-item--level-1 {
      .icon.arrow {
        background-image: url("../../img/green-arrow.svg");
      }
      &:hover {
        .icon.arrow {
          @include translate(10px, 0);
        }
      }
    }
    &.focus .menu-item--level-1 {
      .icon.arrow {
        background-image: url("../../img/blue-arrow.svg");
      }
      &:hover {
        .icon.arrow {
          @include translate(10px, 0);
        }
      }
    }
    &.fusion .menu-item--level-1 {
      .icon.arrow {
        background-image: url("../../img/purple-arrow.svg");
      }
      &:hover {
        .icon.arrow {
          @include translate(10px, 0);
        }
      }
    }
    &.sbg-hub .menu-item--level-1 {
      .icon.arrow {
        background-image: url("../../img/teal-arrow.svg");
      }
      &:hover {
        .icon.arrow {
          @include translate(10px, 0);
        }
      }
    }

    //// Special menu for our Solutions...
    &.motorsport {
      text-align: center;
      @include transition(opacity 0.2s ease-out);

      .icon.arrow {
        display: table;
        margin: 0 auto;
        width: 55px;
        height: 50px;
        margin-bottom: 20px;
        background: url("../../img/Motorsport.svg") no-repeat;
        background-size: contain;
        background-position: center top;
      }

      &:hover * {
        opacity: 0.7;
      }
    }

    &.football {
      text-align: center;
      @include transition(opacity 0.2s ease-out);

      .icon.arrow {
        display: table;
        margin: 0 auto;
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        background: url("../../img/Football.svg") no-repeat;
        background-size: contain;
        background-position: center top;
      }

      &:hover * {
        opacity: 0.7;
      }
    }

    &.automotive {
      text-align: center;
      @include transition(opacity 0.2s ease-out);

      .icon.arrow {
        display: table;
        margin: 0 auto;
        width: 70px;
        height: 50px;
        margin-bottom: 20px;
        background: url("../../img/Automotive.svg") no-repeat;
        background-size: contain;
        background-position: center top;
      }

      &:hover * {
        opacity: 0.7;
      }
    }

    &.all-sports {
      text-align: center;
      @include transition(opacity 0.2s ease-out);

      .icon.arrow {
        display: table;
        margin: 0 auto;
        width: 84px;
        height: 50px;
        margin-bottom: 20px;
        background: url("../../img/All-Sport.svg") no-repeat;
        background-size: contain;
        background-position: center 7px;
      }

      &:hover * {
        opacity: 0.7;
      }
    }
  }
}
.header__logo {
  width: 110px;

  svg {
    width: 100%;
    height: auto;
  }
}

/// needed to keep the border bottom when hovering on the lower menus
.header__menu {
  &:hover,
  &:focus {
    .header__menu-item {
      &:hover,
      &:focus {
        .header__link {
          color: white;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .header__menu-item {
    &:hover,
    &:focus {
      .header__link {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.header__link {
  display: block;
  padding: 12px 22px 11px;

  &:after {
    content: attr(data-link-text);
    position: absolute;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: $sbg_orange;
    display: table-cell;
    height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    bottom: 26px;
  }

  &:hover,
  &:focus {
    color: white;

    &:after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &--login {
    background: white;
    border-radius: $btnRadius;
    color: black;

    &:hover,
    &:focus {
      background: $sbg_orange;
      border-radius: $btnRadius;
      color: white;
    }
  }

  &--active:after,
  &:hover:after,
  &:focus:after {
    opacity: 1;
  }

  &:first-of-type:after {
    ///left: 0;
    transform: none;
  }

  &:nth-of-type(5):after {
    right: 0;
    left: auto;
    transform: none;
  }
}

.header__menu-shadow {
  width: $navWidth;
}

.header__nav--shadow {
  right: $unitX;
  color: black;
  position: absolute;
}

// TODO: consider using clip: rect http://jsfiddle.net/jf3t0fmf/843/
.shadow-wrapper {
  position: fixed;
  height: 60px;
  right: 0;
  width: $rightColWidth;
  overflow: hidden;
}

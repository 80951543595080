.text--dark {
  & p,
  & {
    color: #000;
  }
}

.text--light {
  color: #fff;
}

.p--sm {
  font-size: $fontSize-small;
  line-height: $lineHeight-wide;

  p {
    margin-bottom: 0.4rem;
  }
}
.p {
  font-size: $fontSize-normal;
}
.h--lrg {
  font-size: 1.6rem;
}
.h {
}

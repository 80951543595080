.sidebar {
  position: fixed;
  transform: rotate(90deg) translateX(100%);
  transform-origin: top right;
  top: 0;
  right: 0;
  padding-left: 72px;
  width: calc(#{13 * $unitY});
  height: 0.8 * $siteMarginX;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 8;
  color: black;

  &__filter {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 40px;
    transform: rotate(-90deg);
  }

  &__link {
    &:after {
      @include underline($themeColor-Orange);
    }

    &:focus {
      outline: none;
    }

    &--active:after,
    &:hover:after,
    &:focus:after {
      opacity: 1;
    }
  }
}

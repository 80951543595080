@mixin background-image-filter {
  .background-image__filter {
    background: linear-gradient(to top right, rgba(0, 0, 0, 1), transparent);
    opacity: 0.6;
    transition: opacity 0.7s ease;
  }
  &:hover {
    .background-image__filter {
      opacity: 0.9;
    }
  }
}
@mixin background-image-zoom {
  .background-image {
    transition: transform 0.7s ease;
  }
  &:hover {
    .background-image {
      transform: scale(1.1);
    }
  }
}
@mixin mobile-text-title() {
  font-weight: 700;
  font-size: 36px;
  line-height: $lineHeight-small;
}

@mixin mobile-text-content() {
  color: $textColor-darkGrey;
  line-height: $lineHeight-wide;
}

@mixin body-copy() {
  font-size: $fontSize-small;
  line-height: $lineHeight-wide;
}

@mixin gradient($top, $bottom) {
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    $top 0%,
    $bottom 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    $top 0%,
    $bottom 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/*/
@mixin dont-break-out() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  // -ms-word-break: break-all;
  // /* This is the dangerous one in WebKit, as it breaks things wherever */
  // word-break: break-all;
  // /* Instead use this non-standard one: */
  // word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // hyphens: auto;
}

@mixin underline($color, $thickness: 3px) {
  content: attr(data-link-text);
  position: absolute;
  border-bottom-style: solid;
  border-bottom-width: $thickness;
  border-bottom-color: $color;
  display: table-cell;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  bottom: -6px;
}

@mixin read-more-link(
  $color: $themeColor-Green,
  $thickness: 2px,
  $hoverColor: $themeColor-Green
) {
  font-size: $fontSize-small;
  line-height: $lineHeight-wide;
  position: relative;
  transition: color 0.2 ease;

  &:hover {
    &::after {
      @include underline($hoverColor, $thickness);
      opacity: 1;
      left: 0;
    }
  }

  &::after {
    @include underline($color, $thickness);
    opacity: 1;
    left: 0;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-type-small {
  @include fluid-type(1366px, 1980px, 13px, 20px);
}

@mixin long-form-block {
  width: 70%;
  max-width: $longFormOuterWidth;
  margin: 0 auto;
  justify-content: space-around;
  display: flex;
  padding: 4rem 2rem;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
  }
}
@mixin long-form-block-inner {
  max-width: $longFormInnerWidth;
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@mixin aspect-ratio($width, $height) {
  width: 100%;
  position: relative;
  padding-bottom: $height / $width * 100%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.mobile-about {

  &__header-image {
    height: 210px;
    padding: 0 $unitX;
    display: flex;
    align-items: flex-end;
  }

  &__title {
    @include mobile-text-title;
  }

  &__content {
    @include mobile-text-content;
    padding: $mobileTextPadding;
  }
}

.react-simple-slider {
  box-sizing: border-box;
}

.react-simple-slider *, .react-simple-slider *:before, .react-simple-slider *:after {
  box-sizing: inherit;
}

.react-simple-slider * {
  box-sizing: border-box;
}

.react-simple-slider {
  box-sizing: border-box;
}

.react-simple-slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.react-simple-slider__wrapper--transitioning {
}

.react-simple-slider__wrapper--horizontal {
  height: 100%;
  transform: translateX(0);
}

.react-simple-slider__slide {
  display: block;
}

.react-simple-slider__slide--horizontal {
  height: 100%;
  float: left;
}

.react-simple-slider__slide--vertical {
  width: 100%;
}

$content-width: 30rem;

.contact {
  p {
    color: black;
  }
  &__content-wrapper {
    display: flex;
  }

  &__map {
    flex-basis: 0;
    flex-grow: 1;
    height: 100vh;
    position: relative;
    background-color: $themeColor-LightGrey;
  }

  &__info {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    margin: $siteMarginY 0;
    padding: 0 0.5rem 0 $textPaddingX;
    height: calc(100vh - #{$siteMarginY * 2});

    h2 {
      margin-bottom: 1rem;
    }
  }

  &__address_heading {
    padding: 3rem 3rem 0 3rem;
  }

  &__addresses {
    width: $content-width;
    background: #262626;
  }

  &__address {
    padding: 3rem;
  }

  &__address_title {
    font-size: 24px;
    padding-bottom: 4px;
  }

  &__contact-form {
    display: flex;
    flex-direction: column;
    width: $content-width;
    height: 32rem;
    padding: 3rem;
    margin-bottom: 1.8em;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #26262654;
  }

  &__contact-form-title {
    margin-bottom: 1.5em !important;
  }

  &__jobs {
    margin-bottom: 1.8em;
  }
}

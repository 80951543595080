.siteLoader {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
  opacity: 1;

  @media (max-width: 1024px) {
    z-index: 1000;
  }
}
.image {
  width: 125px;
}
.hidden {
  opacity: 0 !important;
}

.react-fade-carousel {
  box-sizing: border-box;
}

.react-fade-carousel *, .react-fade-carousel *:before, .react-fade-carousel *:after {
  box-sizing: inherit;
}

.react-fade-carousel * {
  box-sizing: border-box;
}

.react-fade-carousel {
  box-sizing: border-box;
}

.react-fade-carousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.react-fade-carousel__wrapper--horizontal {
  height: 100%;
}

.react-fade-carousel__slide {
  display: block;
  position: absolute;
  opacity: 0;

  width: 100%;
  height: 100%;

  display: none;

  &.react-fade-carousel__slide--transitioning {
    display: block;
  }
}

.react-fade-carousel__slide--current {
  opacity: 1;
  display: block;
}

.react-fade-carousel__slide--outgoing {
  opacity: 0;
}

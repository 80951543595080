.mobile-products {
  &__product {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $unitX;
    transition: height linear 0.3s;
    min-height: 140px;

    &:nth-of-type(1) {
      padding-top: $mobileHeaderHeight;
    }

    &--active {
      padding-top: $mobileHeaderHeight;
      padding-bottom: $mobileHeaderHeight;
      min-height: 50vh;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &__product-title {
    margin-bottom: 0;
  }

  &__text-content,
  &__read-more-link {
    font-size: $fontSize-xsmall;
    line-height: $lineHeight-wide;

    p {
      margin-bottom: 0;
      margin-top: 1em;
    }
  }

  &__read-more-link {
    display: block;
    margin-top: 1em;

    &:after {
      content: attr(data-link-text);
      position: absolute;
      border-bottom: 2px solid $themeColor-Green;
      display: table-cell;
      height: 0px;
      overflow: hidden;
    }
  }
}

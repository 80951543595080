.mobile-contact {
  padding: 85px 0 40px;
  overflow: auto;
  font-size: $fontSize-small;

  &__address,
  &__map,
  &__contact-form,
  &__jobs {
    padding-top: 1.6em;
    padding-bottom: 0.6em;
  }

  &__header,
  &__address,
  &__contact-form,
  &__jobs {
    padding-left: $unitX;
    padding-right: $unitX;
  }

  &__map {
    height: 300px;
  }

  &__title {
    font-size: 1.8em;
    margin: 0;
  }

  &__address-title,
  &__contact-form-title {
    font-size: 1em;
    padding-bottom: 1.5em;
  }

  &__address-title {
    margin: 0;
  }
}

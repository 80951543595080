$topHeight: 50vh;
$middleHeight: 23vh;
$bottomHeight: 100vh - $topHeight - $middleHeight;

.mixed-carousel {
  &__image {
    &--0 {
    }

    &--1 {
    }
  }

  &__carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    &--black {
      background-color: rgba(0, 0, 0, 0.4);
    }

    // &--no-rollover {
    //   background-color: rgba(0, 0, 0, 0.7);
    // }
  }

  @media (max-width: 1023px) {
    position: relative;
    float: left;

    &--0 {
      width: 100%;
      height: $topHeight;

      & .react-simple-slider__slide {
        width: 100vw;
        height: 100%;
      }
    }

    &--1 {
      width: 50%;
      height: $middleHeight;
      float: left;

      & .react-simple-slider__slide {
        width: 50vw;
        height: 100%;
      }
    }

    &--2 {
      height: $bottomHeight;
      width: 100vw;

      & .react-simple-slider__slide {
        width: 100vw;
        height: $bottomHeight;
      }

      // & .mixed-carousel__carousel--vertical {
      //   .react-simple-slider__slide {
      //     // TODO: fix this major hack
      //     margin-top: -1px;
      //     margin-left: -1px;
      //     width: calc(100vw + 4px)!important;
      //     height: calc(#{$bottomHeight} + 18px);
      //   }
      // }
    }

    &--vid {
      height: $middleHeight;
      width: 50%;
      float: left;
    }
  }

  @media (min-width: 1024px) {
    position: absolute;

    &--0 {
      width: 8 * $unitX;
      height: 100%;
      border-right: 3px solid black;

      & .react-simple-slider__slide {
        width: 8 * $unitX;
        height: 100vh;
      }
    }

    &--1 {
      right: 0;
      width: 33.3333333vw;
      ///height: 7 * $unitY;
      height: 30vh;
      top: 0;
      border-bottom: 3px solid black;

      & .react-simple-slider__slide {
        width: 33.3333333vw;
        ///height: 7 * $unitY;
        height: 30vh;
        overflow: hidden;
      }

      & .mixed-carousel__carousel--text {
        background: linear-gradient(
          to top right,
          rgba(0, 0, 0, 0.7),
          transparent
        );
      }

      & .background-image {
        transform: scale(1.2);
      }
    }

    &--vid {
      height: 8 * $unitY;
      right: 0;
      width: 66.66666666vw;
      ///top: 50vh;
      top: 30vh;
      transform: scale(0.5);
      transform-origin: top right;
    }

    &--2 {
      height: 5 * $unitY;
      bottom: 0;
      right: 0;
      width: 4 * $unitX;
      border-top: 3px solid black;

      & .react-simple-slider__slide {
        width: 4 * $unitX;
        height: 5 * $unitY;
        overflow: hidden;
      }

      & .mixed-carousel__carousel--text {
        background: linear-gradient(
          to bottom right,
          rgba(0, 0, 0, 0.7),
          transparent
        );
      }

      & .background-image {
        transform: scale(1.2);
      }
    }

    &--3 {
      height: 50vh;
      bottom: 0;
      width: 3 * $unitX;
      right: 4 * $unitX;

      & .react-simple-slider__slide {
        width: 3 * $unitX;
        height: 50vh;
        overflow: hidden;
      }

      & .mixed-carousel__carousel--text {
        background: linear-gradient(
          to bottom right,
          rgba(0, 0, 0, 0.7),
          transparent
        );
      }

      & .background-image {
        transform: scale(1.2);
      }
    }
  }
}

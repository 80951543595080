#app-container {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;

  &__transition-overlay {
    position: fixed;
    z-index: 100;
    height: 200vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: opacity 0.7s ease-out;

    &--active {
      transition: opacity 0.05s ease-out;
      opacity: 1;
    }
  }
}

body {
	
	#jsd-widget {
		opacity: 0;
		transform: translateY(100%);

		-webkit-transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out !important;
		transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out !important;
		
	}

	&.show-jsd-widget {
		#jsd-widget {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
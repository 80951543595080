.menu {
  position: absolute;
  background: #a29aa5;
  color: white;
  left: 0;
  top: 100%;
  display: none;

  &--level-1 {
    top: 0;
    left: 100%;
    background: #fff;
    color: #403f50;
    display: none;
  }
}
.menu-item {
  padding: 1rem;
  position: relative;
  width: 158px;

  &:hover {
    background: #c9c5cc;

    .menu {
      display: block;
    }
  }

  &--level-1 {
    &:hover {
      background: #403f50;
      color: #fff;
    }
  }
}
.menu-item__title {
  cursor: default;
}
.menu-item__right-arrow {
  width: 12px;
  height: 100%;
  position: absolute;
  right: 14px;
  top: 0;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.long-form-footer {
  width: 100%;
  position: relative;
  color: black;
  background: #f5f5f5;
  padding-bottom: calc(#{$unitY} + #{$footerIconHeight});

  input,
  textarea,
  button {
    background: inherit;
  }
}
.long-form-footer__section-wrap {
  @include long-form-block;
}
.long-form-footer__section {
  @include long-form-block-inner;
}
.long-form-footer__section-title {
  margin-bottom: 1rem;
}
.long-form-footer__address {
  margin-bottom: 1rem;
}
.long-form-footer__map {
  height: 160px;
}

.mobile-news {
  // position: fixed;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // overflow-y: scroll;
  background-color: $themeColor-LightGrey;

  &__result-wrap {
    @include background-image-filter;
  }

  &__result {
    min-height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $unitX;

    // transition: height 0.6s ease;

    // &:last-of-type {
    //   height: calc(100vh - 160px);
    // }

    &--active {
      height: 350px;

      &:last-of-type {
        // height: 100vh;
      }
    }

    &-date {
      font-size: $fontSize-small;
      margin: 0.5em 0;
    }

    &-title {
      margin-bottom: 0;
      line-height: $lineHeight-small;
    }
  }
}

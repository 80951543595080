@mixin hover-show-detail() {
  .tile__title {
    transform: translateY(1em);
    transition: transform 0.4s ease-out;
  }
  .tile__detail-extra {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    display: block;
  }

  &:hover {
    .tile__title {
      transform: translateY(0);
    }
    .tile__detail-extra {
      opacity: 1;
      transition: opacity 0.7s ease-in;
    }
  }
}
@mixin tile-xxl() {
  width: 100vw;
  height: 100vh;

  @include hover-show-detail();
  .tile__title {
    @include fluid-type($min-vw, $max-vw, 40px, 62px);
  }
}
@mixin tile-xl() {
  width: 50vw;
  height: 100vh;

  @include hover-show-detail();
  .tile__title {
    @include fluid-type($min-vw, $max-vw, 40px, 62px);
  }
}
@mixin tile-l() {
  width: 50vw;
  height: 50vh;

  @include hover-show-detail();
  .tile__title {
    @include fluid-type($min-vw, $max-vw, 40px, 62px);
  }
}
@mixin tile-m() {
  width: 50vw;
  height: 25vh;
  padding: 3rem;
  justify-content: center;

  .tile__title {
    @include fluid-type($min-vw, $max-vw, 26px, 57px);
  }
}
@mixin tile-s() {
  width: 25vw;
  height: 25vh;
  padding: 3rem;
  justify-content: center;

  .tile__title {
    @include fluid-type($min-vw, $max-vw, 26px, 50px);
  }
}
.tile__title {
  margin-left: -2px;
}
.tile__background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.tile__text-content {
  max-width: 37em;
}
.tile__detail-extra {
  display: none;
  margin-top: 1rem;
  height: 100px;
}
.tile__links {
  margin-top: 1rem;

  a {
    @include read-more-link(white, 2px);
    margin-right: 2rem;
  }
}
.tiles__tile {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  left: 0;
  top: 0;
  padding: $unitY 0.5 * $unitX;
  z-index: 1;

  @include background-image-zoom;
  @include background-image-filter;
}
.tiles--total-1 {
  .tiles__tile {
    @include tile-xxl;
  }
}
.tiles--total-2 {
  .tiles__tile {
    @include tile-xl;
    &-2 {
      left: 50vw;
    }
  }
}
.tiles--total-3 {
  .tiles__tile {
    &-1 {
      @include tile-xl;
    }
    &-2 {
      @include tile-l;
      left: 50vw;
    }
    &-3 {
      @include tile-l;
      left: 50vw;
      top: 50vh;
    }
  }
}
.tiles--total-4 {
  .tiles__tile {
    @include tile-l;
    &-2 {
      left: 0;
      top: 50vh;
    }
    &-3 {
      left: 50vw;
      top: 0;
    }
    &-4 {
      left: 50vw;
      top: 50vh;
    }
  }
}
.tiles--total-5 {
  .tiles__tile {
    &-1 {
      @include tile-l;
    }
    &-2 {
      @include tile-l;
      left: 0;
      top: 50vh;
    }
    &-3 {
      @include tile-l;
      left: 50vw;
      top: 0;
    }
    &-4 {
      @include tile-m;
      left: 50vw;
      top: 50vh;
    }
    &-5 {
      @include tile-m;
      left: 50vw;
      top: 75vh;
    }
  }
}
.tiles--total-6 {
  .tiles__tile {
    &-1 {
      @include tile-l;
    }
    &-2 {
      @include tile-l;
      left: 0;
      top: 50vh;
    }
    &-3 {
      @include tile-m;
      left: 50vw;
      top: 0;
    }
    &-4 {
      @include tile-m;
      left: 50vw;
      top: 25vh;
    }
    &-5 {
      @include tile-m;
      left: 50vw;
      top: 50vh;
    }
    &-6 {
      @include tile-m;
      left: 50vw;
      top: 75vh;
    }
  }
}
.tiles--total-7 {
  .tiles__tile {
    &-1 {
      @include tile-l;
    }
    &-2 {
      @include tile-l;
      left: 0;
      top: 50vh;
    }
    &-3 {
      @include tile-m;
      left: 50vw;
      top: 0;
    }
    &-4 {
      @include tile-s;
      left: 50vw;
      top: 25vh;
    }
    &-5 {
      @include tile-s;
      left: 75vw;
      top: 25vh;
    }
    &-6 {
      @include tile-m;
      left: 50vw;
      top: 50vh;
    }
    &-7 {
      @include tile-m;
      left: 50vw;
      top: 75vh;
    }
  }
}
.tiles--total-8 {
  .tiles__tile {
    &-1 {
      @include tile-l;
    }
    &-2 {
      @include tile-l;
      left: 0;
      top: 50vh;
    }
    &-3 {
      @include tile-m;
      left: 50vw;
      top: 0;
    }
    &-4 {
      @include tile-s;
      left: 50vw;
      top: 25vh;
    }
    &-5 {
      @include tile-s;
      left: 75vw;
      top: 25vh;
    }
    &-6 {
      @include tile-m;
      left: 50vw;
      top: 50vh;
    }
    &-7 {
      @include tile-s;
      left: 50vw;
      top: 75vh;
    }
    &-8 {
      @include tile-s;
      left: 75vw;
      top: 75vh;
    }
  }
}

@media (max-width: 600px) {
  .tiles .tiles__tile {
    @include tile-s;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    justify-content: center;
    padding: $unitY $unitX;
    height: auto;
    min-height: 25vh;

    &-1 {
      padding-top: 3 * $unitY;
    }

    .tile__title {
      transform: translateY(0);
    }
    .tile__detail-extra {
      opacity: 1;
      transition: opacity 0.7s ease-in;
      height: auto;
      margin-top: 0;
    }
    .tile__text-content {
      display: none;
    }
    .tile__links {
      margin-top: 0;
    }
  }
}
